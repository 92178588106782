<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="Add New" 
                :showBtn="false"
                :showAddNew="true"
                title="Sales Invoice list"
                @onClickAddNewButton="navigateToAddPage"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <label class="form-label">Select BDO</label>
                    <AsyncSelect
                        placeholder="Select BDO"
                        v-model="srId"
                        :api-service="fetchAllSR"
                        :reduce="option => option.id"
                        :additional-option="additionalSROption"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <label for="colFormLabel" class="form-label">Bank-Branch</label>
                    <AsyncSelect
                        placeholder="Select Bank-Branch"
                        v-model="dealerId"
                        :api-service="fetchAllDealers"
                        :additional-query="additionalDealerQuery"
                        :reduce="option => option.id"
                        :additional-option="additionalDealerOption"
                        :key="dealerRenderKey"
                        :disabled="! Boolean(srId)"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">

                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Party: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    
                    <AsyncSelect
                        placeholder="Select Party"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalContactOption"
                        :format-label="formatPatientLabel"
                        :key="contactRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 invoice-type">
                    <label for="colFormLabel" class="form-label">Invoice Type</label>
                    <v-select
                        placeholder="Select Invoice Type"
                        v-model="invoiceType"
                        :options="invoiceTypes" 
                        label="name"
                        :reduce="item => item.value"
                    />
                </div>
            </DateQuerySetter>
        </div>
        <div class="col-12 px-2 mt-2">
            <ListTable :purchase="purchaseList" route_name="sales-invoice-edit"/>
        </div>
        <div class="px-2"><Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/></div>
        <ContactDropdownFilter 
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            type="customer"
        />
    </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import { ref, inject, onMounted, computed, watch  } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/sales/ListTable.vue'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleCrm from '@/services/modules/crm/crm'
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'

const router = useRouter()
const store = useStore()
let offset = ref(20)
let purchaseList = ref([])
let contactProfile = ref(null)
const route = useRoute()
const srId = ref(null);
const dealerId = ref(null);
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);
const invoiceType = ref('all');

const {fetchSalesList} = handlePurchase()
const {fetchContactProfiles} = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchAllDealers, fetchAllSR } = handleCrm();
const additionalDealerQuery = ref({});
const additionalContactOption = ref({
    with_mobile: 1,
    dealer_id: '',
    roles: 'customer'
});
const additionalDealerOption = ref([]);
const additionalSROption = ref([]);
const invoiceTypes = ref([
    { name: 'All', value: 'all' },
    { name: 'Template', value: 'template' }
]);

const companyId = computed(() => {
    return route.params.companyId
})
const startDate = computed(() => {
    return route.query.start
})
const endDate = computed(() => {
    return route.query.end
})
const page = computed(() => {
    return route.query.page
})

watch(srId, (newValue, oldValue) => {
    additionalDealerQuery.value = {};
    additionalContactOption.value.dealer_id = '';
    dealerId.value = null;
    contactProfile.value = null;
    dealerRenderKey.value += 1;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalDealerQuery.value = {'sale_representative_id': newValue}
    }
})

watch(dealerId, (newValue, oldValue) => {
    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalContactOption.value.dealer_id = newValue
    }
})

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

function navigateToAddPage() {
    router.push({name: 'sales-invoice-add', params: route.params, query:route.query})
}

function getQuery () {
    let query = '?company_id=' + companyId.value
    if(contactProfile.value) query += '&contact_profile_id='+contactProfile.value.id
    if(!startDate.value && !endDate.value) return query
    if(startDate.value) query += '&start_date='+startDate.value
    if(endDate.value) query += '&end_date='+endDate.value
    if(page.value) query += '&page='+page.value
    if(invoiceType.value) query += '&invoice_type=' + invoiceType.value;
    query += '&offset='+offset.value
    return query
}

function onClickGo() {
    fetchSalesList(getQuery())
    .then(res => {
        if(res.status) {
            purchaseList.value = res.date.data
            setPagination(res.date)
        }else {
            purchaseList.value = []
            store.commit("resetPagination")
        }
        
    })
}

function setPagination(data) {
    if(!data.data.length) return
    store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    setTimeout(() => {
        onClickGo()
    },100)
}

onMounted(() => {
    onClickGo()
})

</script>

<style scoped>
.mt-tpp-1 {
    margin-top: 1%;
}
.filter-label {
    padding-right: 0.5rem !important;
}
.invoice-type {
    margin-top: 2.5% !important;
}
</style>